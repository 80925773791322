import React, { useState } from "react";
import { sortBy } from "lodash";

import { Modal, ModalBody, ModalHeader } from "Molecules";
import { modalNames } from "Constants";
import { getPostponedOrderWindows, usePos } from "Providers";
import { Accordion, AccordionItem, AccordionTitle, AccordionContent, ModificationsList } from "Organisms";
import { calculateTotalPrice, gaProductionEvent } from "Utils";
import { BundleProductItems, OnlineAddonsList } from "Organisms";
import { Header, NewButton as Button, Flex, NewList as List, NewListItem, Text } from "Atoms";
import { Divider } from "Atoms/Divider/Divider";
import { PosModalCloseBtn } from "../shared";
import { CartOrderProductComment } from "../cart/CartOrderProductComment";
import { isVariablePriceType } from "ProductUtils";
import { getUnitStringForPriceType } from "PriceUtils";
import { roundNumber } from "NumberUtils";
import { formatSwedishFinancialNumbers } from "TempUtils";
import { useEffectOnce } from "Hooks";
import { useOrderWindowsStore } from "Stores";
import { GOOGLE_ANALYTICS_EVENT_ACTION, GOOGLE_ANALYTICS_EVENT_CATEGORY, OrderWindow } from "Types";
import { useFetchOrderWindowsForShop } from "../../utils/useFetchOrderWindowsForShop";

type Props = {
    closeModal: Function;
};

export const ActivePhoneOrdersModal: React.FC<Props> = ({ closeModal }) => {
    const [openPhoneOrders, setOpenPhoneOrders] = useState<number[]>([]);

    const [refetchOrderWindows, { data: getOrderWindowsForShopData, loading: isFetchingOrderWindowsForShop }] =
        useFetchOrderWindowsForShop();

    const { onToggleShouldHideOrderWindow } = usePos();
    const { orderWindows } = useOrderWindowsStore();

    useEffectOnce(() => {
        refetchOrderWindows();
    });

    const handleOpenOrder = (orderWindowId: string) => {
        onToggleShouldHideOrderWindow(orderWindowId, false);
        gaProductionEvent({
            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.POS_FOOTER,
            action: GOOGLE_ANALYTICS_EVENT_ACTION.CLICK_BUTTON,
            label: "Open parked orders"
        });
        closeModal(modalNames.ACTIVE_PHONE_ORDERS_MODAL);
    };

    const handleOpenPhoneOrders = (index: number) => {
        setOpenPhoneOrders(currOpen => {
            if (currOpen.includes(index)) {
                return currOpen.filter(openIndex => openIndex !== index);
            }
            return currOpen.concat(index);
        });
    };

    const getPostponedOrderHeaderText = (postponeOrderWindow: OrderWindow) => {
        const orderName = postponeOrderWindow.contactInformation?.name ?? "";
        const orderNo = !!postponeOrderWindow?.orderNo ? ` #${postponeOrderWindow?.orderNo}` : "";
        return `Order${orderNo}: ${orderName}`;
    };

    const postponeOrderWindows = getPostponedOrderWindows(orderWindows);
    const sortedOrderWindows = sortBy(postponeOrderWindows, "updatedAt");

    return (
        <Modal open={true} isScrolling>
            <ModalHeader>
                <Flex align="center" justify="space-between">
                    <Header as="h1" size="xl" m="0">
                        Parkerade ordrar ({postponeOrderWindows.length} st)
                    </Header>
                    <PosModalCloseBtn closeModal={() => closeModal(modalNames.ACTIVE_PHONE_ORDERS_MODAL)} />
                </Flex>
            </ModalHeader>
            <ModalBody>
                {!postponeOrderWindows.length ? (
                    <Header as="h3" size="md" textAlign="center" color="gray.700">
                        Parkerade ordrar visas här
                    </Header>
                ) : (
                    <Accordion multi activeIndexes={openPhoneOrders}>
                        {sortedOrderWindows.map((postponeOrderWindow, index) => {
                            const hasPhoneNumber = postponeOrderWindow.contactInformation?.phoneNumber;
                            const hasComment = postponeOrderWindow.comment;
                            const isPostponeOrderWindowOpen = !postponeOrderWindow.shouldHide;

                            return (
                                <AccordionItem key={postponeOrderWindow.id}>
                                    <AccordionTitle onClick={() => handleOpenPhoneOrders(index)}>
                                        <Header as="h3" size="lg" m="0">
                                            {getPostponedOrderHeaderText(postponeOrderWindow)}
                                        </Header>
                                        <Button as="div" size="lg">
                                            {openPhoneOrders.includes(index) ? "Stäng" : "Öppna"}
                                        </Button>
                                    </AccordionTitle>
                                    <AccordionContent>
                                        <Flex mb={8}>
                                            <Flex direction="column" flex="40%" mr={4}>
                                                <Header as="h4" size="md">
                                                    Uppgifter
                                                </Header>
                                                {hasPhoneNumber && (
                                                    <Text fontSize="lg" m="0">
                                                        - Tel: {postponeOrderWindow.contactInformation?.phoneNumber}
                                                    </Text>
                                                )}
                                                {hasComment && (
                                                    <Text fontSize="lg" wordBreak="break-word">
                                                        - Kommentar: {postponeOrderWindow.comment}
                                                    </Text>
                                                )}
                                            </Flex>
                                            <Flex direction="column" flex="60%">
                                                <Header as="h4" size="md">
                                                    Produkter
                                                </Header>
                                                <List listStyle="none" stretch={2}>
                                                    {postponeOrderWindow?.cartProducts?.map(cartProduct => {
                                                        const { orderProduct } = cartProduct;
                                                        return (
                                                            <NewListItem key={cartProduct.id}>
                                                                <Flex direction="column">
                                                                    <Flex justify="space-between">
                                                                        {isVariablePriceType(orderProduct.priceType) ? (
                                                                            <Text fontSize="lg" m="0">
                                                                                {orderProduct.weight}{" "}
                                                                                {getUnitStringForPriceType(
                                                                                    orderProduct.priceType
                                                                                )}{" "}
                                                                                {orderProduct.name}
                                                                            </Text>
                                                                        ) : (
                                                                            <Text fontSize="lg" m="0">
                                                                                {orderProduct.quantity} st{" "}
                                                                                {orderProduct.name}
                                                                            </Text>
                                                                        )}
                                                                        <Text fontSize="lg" m="0">
                                                                            {formatSwedishFinancialNumbers(
                                                                                orderProduct.totalPrice
                                                                            )}
                                                                        </Text>
                                                                    </Flex>
                                                                    {orderProduct.selectedBundleProductItems && (
                                                                        <BundleProductItems
                                                                            hasOnlineCtx={false}
                                                                            ml={4}
                                                                            bundleProductItems={
                                                                                orderProduct.selectedBundleProductItems
                                                                            }
                                                                        />
                                                                    )}
                                                                    {orderProduct.modifications && (
                                                                        <ModificationsList
                                                                            ml={4}
                                                                            modifications={
                                                                                orderProduct.modifications as any
                                                                            }
                                                                        />
                                                                    )}
                                                                    {orderProduct.comment && (
                                                                        <CartOrderProductComment
                                                                            comment={orderProduct.comment}
                                                                        />
                                                                    )}
                                                                    {orderProduct.addons && (
                                                                        <OnlineAddonsList
                                                                            addons={orderProduct.addons}
                                                                        />
                                                                    )}
                                                                </Flex>
                                                            </NewListItem>
                                                        );
                                                    })}
                                                </List>
                                                <Divider mt={4} mb={2} />
                                                <Text fontSize="lg" textAlign="right" fontWeight="bold">
                                                    Totalt:{" "}
                                                    {roundNumber(
                                                        calculateTotalPrice(
                                                            postponeOrderWindow?.cartProducts?.map(c => c.orderProduct)
                                                        )
                                                    )}{" "}
                                                    kr
                                                </Text>
                                            </Flex>
                                        </Flex>
                                        <Button
                                            width="100%"
                                            size="xl"
                                            bg="#89cb9c"
                                            _hover={{
                                                bg: "#89cb9c"
                                            }}
                                            color="white"
                                            isDisabled={isPostponeOrderWindowOpen}
                                            onClick={() => handleOpenOrder(postponeOrderWindow.id)}
                                        >
                                            {isPostponeOrderWindowOpen ? "Order öppnad" : "Öppna order"}
                                        </Button>
                                    </AccordionContent>
                                </AccordionItem>
                            );
                        })}
                    </Accordion>
                )}
            </ModalBody>
        </Modal>
    );
};
